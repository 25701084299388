/* eslint-disable require-jsdoc */
import { apiGet, apiPost, apiPut } from '../utils/request';
import { Event } from '../models/event';

export class EventService {
  private path = 'event';

  public async createEvent(event: Event): Promise<Event> {
    return apiPost(this.path, event);
  }

  public async getEvents(): Promise<Event[]> {
    return apiGet(this.path);
  }

  public async updateEvent(event: Event): Promise<Event> {
    return apiPut(`${this.path}/${event.id}`, event);
  }
}
