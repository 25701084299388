import { atom } from 'recoil';
import { Event } from '../models/event';

export const userEvents = atom<Event[]>({
  key: 'events',
  default: [],
});

export const eventForm = atom<boolean>({
  key: 'eventForm',
  default: false,
});
