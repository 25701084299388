import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { EventService } from '../services/event-service';
import { userEvents } from '../atom/events';

/**
 * Main Page
 * @export
 * @return {*}  {JSX.Element}
 */
export function Main(): JSX.Element {
  const navigate = useNavigate();
  const eventsAtom = useRecoilState(userEvents);
  const eventService = new EventService();
  eventService
    .getEvents()
    .then(events => {
      eventsAtom[1](events);
      navigate('/events');
    })
    .catch(console.error);
  return <div>Loading Events...</div>;
}
