/* eslint-disable require-jsdoc */
import * as React from 'react';
import { useRecoilValue, useRecoilState } from 'recoil';
import { Box, Button } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { userEvents, eventForm } from '../../atom/events';
import { formateDateForGrid } from '../../utils/model';

export function EventGrid(): JSX.Element {
  const eventFormState = useRecoilState(eventForm);

  const columns: GridColDef[] = [
    {
      field: 'title',
      headerName: 'Title',
      width: 200,
    },
    {
      field: 'locationId',
      headerName: 'Location',
      width: 100,
    },
    {
      field: 'eventStart',
      headerName: 'Start',
      width: 200,
      valueFormatter: formateDateForGrid,
    },
    {
      field: 'eventEnd',
      headerName: 'End',
      width: 200,
      valueFormatter: formateDateForGrid,
    },
  ];
  const events = useRecoilValue(userEvents);
  return (
    <Box sx={{ height: 800, width: '100%' }}>
      <Button onClick={() => eventFormState[1](true)}>Create New</Button>
      <DataGrid
        rows={events}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[5]}
        disableSelectionOnClick
      />
    </Box>
  );
}
