/* eslint-disable require-jsdoc */
import * as React from 'react';

interface MapProps extends google.maps.MapOptions {
  onClick?: (e: google.maps.MapMouseEvent) => void;
  onIdle?: (map: google.maps.Map) => void;
  children?: React.ReactNode;
}

export function Map(props: MapProps) {
  const ref = React.useRef<HTMLDivElement>(null);
  const [map, setMap] = React.useState<google.maps.Map>();

  React.useEffect(() => {
    if (ref.current && !map) {
      setMap(
        new window.google.maps.Map(ref.current, {
          ...props,
          center: { lat: 0, lng: 0 },
          zoom: 4,
        }),
      );
    }
  }, [ref, map]);

  return (
    <>
      <div ref={ref} style={{ height: '1000px' }} />
      {React.Children.map(props.children, child => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, { map });
        }
      })}
    </>
  );
}
