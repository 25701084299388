/* eslint-disable require-jsdoc */
import { API } from 'aws-amplify';
import { MAIN_API } from '../configs/aws';
import { deserialize, serialize } from './model';

const apiName = MAIN_API.name;

export async function apiGet<T>(path: string): Promise<T> {
  return new Promise((resolve, reject) => {
    API.get(apiName, `/${path}`, {})
      .then(res => resolve(deserialize(res)))
      .catch(reject);
  });
}

export async function apiPost<T>(path: string, body: T): Promise<T> {
  return new Promise((resolve, reject) => {
    API.post(apiName, `/${path}`, { body: serialize(body) })
      .then(res => resolve(deserialize(res)))
      .catch(reject);
  });
}

export async function apiPut<T>(path: string, body: T): Promise<T> {
  return new Promise((resolve, reject) => {
    API.put(apiName, `/${path}`, { body: serialize(body) })
      .then(res => resolve(deserialize(res)))
      .catch(reject);
  });
}
