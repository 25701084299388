export const COGNITO = {
  REGION: 'us-east-1',
  USER_POOL_ID: 'us-east-1_iUxMxXFwr',
  APP_CLIENT_ID: '7r16h2c0q3mrqc3hol055efaoc',
};

export const MAIN_API = {
  name: 'xp-tracker-Api-A7U3SDII5YTI',
  endpoint: 'https://api.xp.baileyeverts.net',
};
