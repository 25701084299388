import React from 'react';
import './App.css';
import { RecoilRoot } from 'recoil';
import { createTheme, ThemeProvider } from '@mui/material';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Amplify, Auth } from 'aws-amplify';
import { COGNITO, MAIN_API } from './configs/aws';
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import { Main } from './components/Main';
import { TopBar } from './components/TopBar';
import { MapWrapper } from './components/map/MapWrapper';
import { EventGrid } from './components/events/EventGrid';

Amplify.configure({
  aws_cognito_region: COGNITO.REGION,
  aws_user_pools_id: COGNITO.USER_POOL_ID,
  aws_user_pools_web_client_id: COGNITO.APP_CLIENT_ID,
  API: {
    endpoints: [
      {
        ...MAIN_API,
        custom_header: async () => {
          // return { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` }
          return {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`,
          };
        },
      },
    ],
  },
});

/**
 * App Entry Point
 * @param {*} { user }
 * @return {*}  {JSX.Element}
 */
function App({ user, signOut }: any): JSX.Element {
  const theme = createTheme({});
  console.log(user);
  return (
    <RecoilRoot>
      <ThemeProvider theme={theme}>
        <Router>
          <TopBar signOut={signOut} />
          <Routes>
            <Route path="/" element={<Main />} />
            <Route path="/events" element={<EventGrid />} />
            <Route path="/map" element={<MapWrapper />} />
          </Routes>
        </Router>
      </ThemeProvider>
    </RecoilRoot>
  );
}
export default withAuthenticator(App);
