/* eslint-disable require-jsdoc */
import * as React from 'react';
import { Wrapper, Status } from '@googlemaps/react-wrapper';
import { Map } from './Map';
import { Marker } from './Marker';

export function MapWrapper() {
  const render = (status: Status) => {
    return <h1>{status}</h1>;
  };

  const markerOpts: google.maps.MarkerOptions = {
    position: {
      lng: 0,
      lat: 1,
    },
    label: 'farts',
  };

  return (
    <div>
      <Wrapper
        apiKey={'AIzaSyCpPo9kTaXe5-ZXC5ix1_C8Jn61WhfjURc'}
        render={render}
      >
        <Map>
          <Marker {...markerOpts} />
        </Map>
      </Wrapper>
    </div>
  );
}
