import { GridValueFormatterParams } from '@mui/x-data-grid';

/**
 * Converts keys in a model from camelCase to snake_case
 * so { 'somethingCool': 'me'} would change to {'something_cool': 'me'}
 * @export
 * @param {any} modelToConvert
 * @return {*}  {any}
 */
export function serialize(modelToConvert: any): any {
  if (modelToConvert instanceof Array) {
    return modelToConvert.map(serialize);
  } else {
    const convertedModel: any = {};
    Object.keys(modelToConvert).forEach((key: string) => {
      const snakeKey = key.replace(
        /[A-Z]/g,
        letter => `_${letter.toLowerCase()}`,
      );
      convertedModel[snakeKey] = modelToConvert[key];
    });
    return convertedModel;
  }
}

/**
 * Converts keys in a model from snake_cakse to camelCase
 * so { 'something_cool': 'me'} would change to {'somethingCool': 'me'}
 * @export
 * @param {any} modelToConvert
 * @return {*}  {any}
 */
export function deserialize(modelToConvert: any): any {
  console.log(modelToConvert);
  if (modelToConvert instanceof Array) {
    return modelToConvert.map(deserialize);
  } else {
    const convertedModel: any = {};
    Object.keys(modelToConvert).forEach((key: string) => {
      const camelKey = key.replace(/([-_]\w)/g, g => g[1].toUpperCase());
      convertedModel[camelKey] = modelToConvert[key];
    });
    return convertedModel;
  }
}

// eslint-disable-next-line require-jsdoc
export function formateDateForGrid(params: GridValueFormatterParams<string>) {
  return new Date(params.value).toLocaleString();
}
